.header-links li span {
	position: relative;
	z-index: 0;
}

.header-links li:hover {
	color: rgb(29 78 216);
}

.dropdown:hover .dropdown-menu {
	display: block;
}

.parent.clicked .child {
	opacity: 1;
	height: auto;
	overflow: none;
	transform: translateY(0);
}
.child {
	opacity: 0;
	height: 0;
	overflow: hidden;
	transform: translateY(-10%);
}
