@tailwind base;
@tailwind components;
@tailwind utilities;

.video-copy {


    mix-blend-mode: multiply;
}

.video {
 
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.line {
  
background: #fff;

line-height: 100px !important;
text-align: center;
text-transform: uppercase;
color: #000;

mix-blend-mode: screen;
}

@media (min-width: 768px) { 
    .line {
        line-height: 200px !important;
    }
   
 }

.navigate-btn {
    padding: 8px 15px;

   
   
    border-radius: 5%;
    text-decoration: none;    
    text-align: center;
   
   margin-top: 1rem;
    color: white;
	background-color: #864AF9;
	transition: .2s ease-in-out;
	border-left: 3px transparent solid;
	border-right: 3px transparent solid;
}

.navigate-btn:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.20), 0 6px 20px 0 rgba(0, 0, 0, 0.20);
    color: #864AF9;
	background-color: white;
	border-left: #864AF9 3px solid;
	border-right: #864AF9 3px solid;
}


.navigate-btn4 {
    padding: 15px 25px;

   
   
    border-radius: 5%;
    text-decoration: none;
    text-align: center;
   
   margin-top: 1rem;
    color: white;
	background-color: #000000;
	transition: .2s ease-in-out;
	border-left: 3px transparent solid;
	border-right: 3px transparent solid;
}

.navigate-btn4:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.20), 0 6px 20px 0 rgba(0, 0, 0, 0.20);
    color: #000000;
	background-color: white;
	border-left: #000000 3px solid;
	border-right: #000000 3px solid;
}

.navigate-btn3 {
    padding: 15px 25px;

   
   
    border-radius: 5%;
    text-decoration: none;
    text-align: center;
   
   margin-top: 1rem;
    color: rgb(0, 0, 0);
	background-color: #ffffff;
	transition: .2s ease-in-out;
	border-left: 3px transparent solid;
	border-right: 3px transparent solid;
}

.navigate-btn3:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.20), 0 6px 20px 0 rgba(0, 0, 0, 0.20);
    color: #ffffff;
	background-color: rgb(0, 0, 0);
	border-left: #ffffff 3px solid;
	border-right: #ffffff 3px solid;
}

.finance::after {
    content: "";
    /* background-color: rgba(0, 0, 0, 0.2); */
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
    left: 0%;
    z-index: 5;
}

#eapps-instagram-feed-1.a elfsight-app-b31bdbf0-42b0-48fd-bb5d-0171fa681bd9.a eapps-instagram-feed.a es-widget.a eapps-widget.a eapps-widget-show-toolbar.a {
    display: none !important;
    visibility: hidden !important;
    background-color: #000000;
    margin: 60rem !important;


}
