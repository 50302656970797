* {
    font-family: Nunito, sans-serif;
  }
  
  .swiper-container {
    width: 100%;
    height: 100%;
  }
  
  .text-blk {
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  
  .responsive-container-block {
    min-height: 75px;
    height: fit-content;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-top: 0px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    justify-content: flex-start;
  }
  
  .outer-container {
    padding-top: 10px;
    padding-right: 30px;
    padding-bottom: 10px;
    padding-left: 30px;
  }
  
  .inner-container {
    max-width: 1320px;
    flex-direction: column;
    margin-top: 80px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 200px;
  }
  
  .card-container {
    overflow-x: visible;
    overflow-y: visible;
  }
  
  .heading-text {
    font-size: 48px;
    line-height: 65px;
    font-weight: 900;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 30px;
    margin-left: 0px;
    padding-top: 10px;
    padding-right: 10px;
    padding-bottom: 10px;
    padding-left: 0px;
  }
  
  .name {
    font-size: 18px;
    line-height: 24px;
    font-weight: 800;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  
  .team-member-image {
    height: 230px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
    width: 100%; /* Ensures the image fills its container */
  height: 200px; /* Adjust the height as needed to ensure uniformity */
  object-fit: cover;
  }
  
  .card {
    display: block;
  }
  
  .position {
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
    padding-left: 0px;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  
  .swiper-slide {
    justify-content: flex-start;
    display: flex;
  }
  
  .arrow-right {
    transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1);
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 20px;
    cursor: pointer;
    height: 14px;
    width: 24px;
  }
  
  .arrow-left {
    height: 14px;
    transform: rotateX(0deg) rotateY(180deg) rotateZ(0deg) scaleX(1) scaleY(1) scaleZ(1);
    cursor: pointer;
  }
  
  .btn {
    display: flex;
    margin-top: 0px;
    margin-right: 0px;
    margin-bottom: 30px;
    margin-left: 0px;
    flex-direction: row-reverse;
  }
  
  .swiper-pagination {
    position: static;
    text-align: left;
    margin-top: 30px;
    margin-right: 0px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  
  .team-swiper {
    overflow: hidden;
  }
  
  @media (max-width: 768px) {
    .inner-container {
      margin-top: 80px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
      justify-content: center;
    }
  
    .card-container {
      margin-top: 0px;
      margin-right: auto;
      margin-bottom: 0px;
      margin-left: auto;
    }
  
    .swiper-slide {
      justify-content: center;
    }
  
    .heading-text {
      text-align: center;
    }
  
    .swiper-pagination {
      text-align: center;
    }
  
    .arrow-left {
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 20px;
    }
  
    .arrow-right {
      height: 14px;
    }
  
    .swiper-slide.empty {
      display: none;
    }
  }
  
  @media (max-width: 500px) {
    .inner-container {
      margin-top: 80px;
      margin-right: auto;
      margin-bottom: 0px;
      margin-left: auto;
      align-items: flex-start;
    }
  
    .card-container {
      margin-top: 0px;
      margin-right: auto;
      margin-bottom: 0px;
      margin-left: auto;
    }
  
    .heading-text {
      text-align: center;
    }
  
    .card-container {
      align-items: flex-start;
    }
  
    .btn {
      justify-content: flex-start;
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 30px;
      margin-left: 0px;
      text-align: left;
    }
  
    .arrow-left {
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 0px;
      margin-left: 0px;
    }
  
    .team-member-image {
      width: 240px;
      height: 200px;
    }
  
    .outer-container {
      padding-top: 10px;
      padding-right: 0px;
      padding-bottom: 10px;
      padding-left: 20px;
    }
  
    .btn {
      margin-top: 0px;
      margin-right: 0px;
      margin-bottom: 30px;
      margin-left: 10px;
    }
  }
  @import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;600;700;800&amp;display=swap');

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
}

.wk-desk-1 {
  width: 8.333333%;
}

.wk-desk-2 {
  width: 16.666667%;
}

.wk-desk-3 {
  width: 25%;
}

.wk-desk-4 {
  width: 33.333333%;
}

.wk-desk-5 {
  width: 41.666667%;
}

.wk-desk-6 {
  width: 50%;
}

.wk-desk-7 {
  width: 58.333333%;
}

.wk-desk-8 {
  width: 66.666667%;
}

.wk-desk-9 {
  width: 75%;
}

.wk-desk-10 {
  width: 83.333333%;
}

.wk-desk-11 {
  width: 91.666667%;
}

.wk-desk-12 {
  width: 100%;
}

@media (max-width: 1024px) {
  .wk-ipadp-1 {
    width: 8.333333%;
  }

  .wk-ipadp-2 {
    width: 16.666667%;
  }

  .wk-ipadp-3 {
    width: 25%;
  }

  .wk-ipadp-4 {
    width: 33.333333%;
  }

  .wk-ipadp-5 {
    width: 41.666667%;
  }

  .wk-ipadp-6 {
    width: 50%;
  }

  .wk-ipadp-7 {
    width: 58.333333%;
  }

  .wk-ipadp-8 {
    width: 66.666667%;
  }

  .wk-ipadp-9 {
    width: 75%;
  }

  .wk-ipadp-10 {
    width: 83.333333%;
  }

  .wk-ipadp-11 {
    width: 91.666667%;
  }

  .wk-ipadp-12 {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .wk-tab-1 {
    width: 8.333333%;
  }

  .wk-tab-2 {
    width: 16.666667%;
  }

  .wk-tab-3 {
    width: 25%;
  }

  .wk-tab-4 {
    width: 33.333333%;
  }

  .wk-tab-5 {
    width: 41.666667%;
  }

  .wk-tab-6 {
    width: 50%;
  }

  .wk-tab-7 {
    width: 58.333333%;
  }

  .wk-tab-8 {
    width: 66.666667%;
  }

  .wk-tab-9 {
    width: 75%;
  }

  .wk-tab-10 {
    width: 83.333333%;
  }

  .wk-tab-11 {
    width: 91.666667%;
  }

  .wk-tab-12 {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .wk-mobile-1 {
    width: 8.333333%;
  }

  .wk-mobile-2 {
    width: 16.666667%;
  }

  .wk-mobile-3 {
    width: 25%;
  }

  .wk-mobile-4 {
    width: 33.333333%;
  }

  .wk-mobile-5 {
    width: 41.666667%;
  }

  .wk-mobile-6 {
    width: 50%;
  }

  .wk-mobile-7 {
    width: 58.333333%;
  }

  .wk-mobile-8 {
    width: 66.666667%;
  }

  .wk-mobile-9 {
    width: 75%;
  }

  .wk-mobile-10 {
    width: 83.333333%;
  }

  .wk-mobile-11 {
    width: 91.666667%;
  }

  .wk-mobile-12 {
    width: 100%;
  }
}