/* fixed social*/
#fixed-social {
	position: fixed;
	top: 150px;
}

#fixed-social a {
	color: #fff;
	display: block;
	height: 40px;
	position: relative;
	text-align: center;
	line-height: 40px;
    padding: 5px;
	width: 40px;
	margin-bottom: 1px;
	z-index: 2;
}
#fixed-social a:hover > span {
	visibility: visible;
	left: 41px;
	opacity: 1;
}
#fixed-social a span {
	line-height: 40px;
	left: 60px;
	position: absolute;
	text-align: center;
    top: 0;
	width: 120px;
	visibility: hidden;
	transition-duration: 0.5s;
	z-index: 1;
	opacity: 0;
}
.fixed-facebook {
	background-color: #00aae5;
}
.fixed-facebook span {
	background-color: #00aae5;
}
.fixed-twitter {
	background-color: #7d3895;
}
.fixed-twitter span {
	background-color: #7d3895;
}
.fixed-gplus {
	background-color: #00af54;
}
.fixed-gplus span {
	background-color: #00af54;
}
.fixed-linkedin {
	background-color: #ffc41e;
}
.fixed-linkedin span {
	background-color: #ffc41e;
}
.fixed-instagrem {
	background-color: #ed2b29;
}
.fixed-instagrem span {
	background-color: #ed2b29;
}
.fixed-tumblr {
	background-color: #eb1471;
}
.fixed-tumblr span {
	background-color: #eb1471;
}
/*end fixed social*/
